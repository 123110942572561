import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | NexosPark
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у галузі права "} />
			<meta property={"og:title"} content={"Головна | NexosPark"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у галузі права "} />
			<meta property={"og:image"} content={"https://nexosparkwin.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexosparkwin.com/img/13097757.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexosparkwin.com/img/13097757.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexosparkwin.com/img/13097757.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexosparkwin.com/img/13097757.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexosparkwin.com/img/13097757.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexosparkwin.com/img/13097757.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.4) 0%,rgba(255, 255, 255, 0.4) 100%),url(https://nexosparkwin.com/img/1.jpg) 0% center/cover no-repeat"
			min-height="60vh"
			lg-padding="140px 0 88px 0"
			quarkly-title="Hero-21"
			padding="88px 0 88px 0"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
			/>
			<Text
				color="--primary"
				font="--headline1"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				margin="0px 0px 42px 0px"
				lg-width="100%"
				md-width="100%"
				lg-text-align="center"
				lg-margin="0px 0px 20px 0px"
				lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
				letter-spacing="10px"
			>
				NexosPark
			</Text>
			<Text
				lg-text-align="center"
				text-align="center"
				width="80%"
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--lead"
				lg-width="100%"
			>
				У NexosPark Firm ми пишаємось тим, що надаємо виняткові юридичні послуги, що відповідають унікальним потребам наших клієнтів. Наша команда досвідчених адвокатів прагне надавати комплексні юридичні рішення з професіоналізмом, чесністю та відданістю справі. Якщо ви зіткнулися зі складною юридичною проблемою або просто потребуєте грамотної юридичної ради, ми допоможемо вам впевнено та легко орієнтуватися у правовому полі.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://nexosparkwin.com/img/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Про Nexspark
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Фірма NexosPark пропонує широкий спектр юридичних послуг у різних галузях практики. Наша місія – забезпечити першокласне юридичне представництво та консультування приватних осіб, підприємств та організацій. Маючи багаторічний досвід та послужний список успішних проектів, наші адвокати привносять у кожну справу багаті знання та досвід. Ми розуміємо, що ситуація кожного клієнта унікальна, і прагнемо запропонувати індивідуальні рішення, що відповідають його конкретним потребам та цілям.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому варто вибрати NexosPark?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Команда юристів-експертів
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наші адвокати володіють високою кваліфікацією та знаннями у своїх галузях, що гарантує, що ви отримаєте найкраще юридичне представництво з можливих.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Індивідуальний підхід
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Ми приділяємо час тому, щоб зрозуміти вашу унікальну ситуацію та адаптувати наші послуги до ваших конкретних потреб.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Перевірений досвід
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Завдяки історії успішних результатів справ ви можете бути впевнені, що ми маємо досвід та знання для ефективного вирішення ваших юридичних питань.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Комплексні рішення
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Починаючи з первинної консультації та закінчуючи остаточним рішенням, ми надаємо комплексну юридичну підтримку на кожному етапі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			md-justify-content="center"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.1) 67.71%,rgba(4, 8, 12, 0.1) 100%),url(https://nexosparkwin.com/img/3.jpg) center center/cover no-repeat"
			min-height="60vh"
			sm-padding="40px 0 40px 0"
			quarkly-title="Hero-24"
			padding="0px 0 0px 0"
			md-align-items="center"
		>
			<Override
				slot="SectionContent"
				max-width="none"
				width="100%"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="flex-end"
				align-items="flex-end"
			/>
			<Box
				display="flex"
				align-items="flex-start"
				justify-content="center"
				padding="36px 48px 56px 48px"
				background="linear-gradient(120.99deg, #04080C 0%, rgba(25, 30, 34, 0.7) 100%)"
				flex-direction="column"
				width="30%"
				lg-width="50%"
				md-width="60%"
				sm-width="90%"
			>
				<Text
					lg-width="100%"
					lg-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="0px 0px 17px 0px"
					color="--light"
					font="--headline2"
				>
					Готові розпочати роботу?
				</Text>
				<Text
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					У компанії NexosPark ми прагнемо надавати юридичні послуги найвищої якості, щоб допомогти вам досягти ваших цілей. Зв'яжіться з нами сьогодні, щоб призначити консультацію та зробити перший крок до вирішення ваших юридичних питань.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});